var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_reminder_description_page bbox d-flex align-start",
    },
    [
      _c("img", {
        staticClass: "icon mr-10",
        attrs: {
          src: require("@/assets/images/reminder/description.png"),
          alt: "",
        },
      }),
      _c("el-input", {
        staticClass: "textarea",
        attrs: {
          type: "textarea",
          placeholder: "填写提醒描述",
          maxlength: "80",
          autosize: { minRows: 1, maxRows: 3 },
          "show-word-limit": "",
          resize: "none",
        },
        nativeOn: {
          change: function ($event) {
            return _vm.changDescription()
          },
        },
        model: {
          value: _vm.description,
          callback: function ($$v) {
            _vm.description = $$v
          },
          expression: "description",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }