<template>
  <el-popover
    placement="bottom-start"
    popper-class="components_pop_up_reminder_time_time_page_popover"
    trigger="click"
    v-model="switch_popover"
    :visible-arrow="false"
    :disabled="poppver_disable_status"
  >
    <template
      slot="reference"
    >
      <div class="components_pop_up_reminder_time_time_page bbox d-flex align-center cp flex-shrink-0 justify-center un_sel"
      >
        <div class="text"
          :class="{no_content}"
        >{{content}}</div>
          <el-time-picker
            class="picker"
            ref="picker"
            :clearable="false"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            :default-value="defaultValue()"
            format="HH:mm"
            value-format="HH:mm"
            v-show="picker_status"
            v-model="picker_time"
            @change="changePicker"
          ></el-time-picker>
        <!-- <div class="icon"></div> -->
      </div>
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    ptype: {
      type: Number,
      default: 1,
    },
    time: {
      type: String,
      default: '',
    },
  },
  computed:{
    picker_status(){
      const {ptype} = this;
      return true;
    },
    poppver_disable_status(){
      const {picker_status} = this;

      if(picker_status){
        return true;
      }
      return false;
    },
    no_content(){
      const {ptype, time} = this;
      if(time){
        return false;
      }
      return true;
    },
    content(){
      const {ptype, time} = this;
      if(time){
        return time;
      }
      return '时间'
    }
  },
  data(){
    return {
      switch_popover: false,
      pickerOptions: {
        format: 'HH:mm',
      },
      picker_time: '',
      // time: '',
    }
  },
  methods: {
    touchButton(){
      // this.$refs.picker.handleFocus();
    },
    defaultValue(){
      let tim = new Date();
      tim.setHours(0);
      tim.setMinutes(0);
      const {time} = this;
      if(time){
        tim.setHours(time.split(':')[0]);
        tim.setMinutes(time.split(':')[1]);
      }
   
      return tim.getTime();
    },
    changePicker(val){
      this.$emit('handleChangeTime', val);
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_time_time_page{
  min-width: 60px;
  max-width: 200px;
 
  line-height: 34px;
  background: #FBFBFB;
  border-radius: 4px;
  border: 1px solid #E5E7EA;
  position: relative;
  z-index: 5;
  .text{
    &.no_content{
      color: #bbb;
    }
  }
  .icon{
    width: 9px;
    height: 6px;
    background-image: url(~@/assets/images/reminder/select_icon.png)
  }
  .picker{
    position: absolute;
    top:0;
    left:0 ;
    z-index: 5;
    opacity: 0;
    width: 100%;
    height: 100%;
    // overflow: hidden;
  }
}
</style>
<style lang="scss">
.components_pop_up_reminder_time_time_page_popover{
  padding: 5px 0;
  // margin-top: 0px!important;
  min-width: 0!important;
  .list{
    .item{
      @include transition;
      width: 90px;
      background: #FFFFFF;
      font-size: 14px;
      color: #000;
      line-height: 34px;
      padding: 0 10px;
      &:hover{
        font-weight: bold;
      }
      &.active{
        background-color: #ddd;
        font-weight: bold;
        color:#333;
        // &:hover{
        //   background-color: #ddd;
        // }
      }
    }
  }
}
.components_pop_up_reminder_time_time_page{
  .el-date-editor{
    .el-input__inner{
      width: 100%;
      height: 100%;
      padding: {
        left: 0;
        right: 0;
        border: none;
      }
    }
    .el-input__prefix{
      display: none;
    }
  }
}
</style>