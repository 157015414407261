<template>
  <el-popover
    placement="bottom-start"
    popper-class="components_pop_up_reminder_time_operating_page_popover"
    trigger="click"
    v-model="switch_popover"
    :visible-arrow="false"
  >
    <div class="list">
      <template
        v-for="(item, index) in type_list"
      >
        <div class="item bbox cp"
          :key="index"
          :class="{active: item.type == ptype}"
          @click="changeType(item.type)"
        >{{item.name}}</div>
      </template>
      
    </div>
    <template
      slot="reference"
    >
      <div class="components_pop_up_reminder_time_operating_page bbox d-flex align-center cp flex-shrink-0 justify-center un_sel">
        <div class="text">{{type_name}}</div>
        <div class="icon"></div>
      </div>
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    ptype: {
      type: Number,
      default: 1,
    },
  },
  data(){
    return {
      switch_popover: false,
      type_list: [
        {name:'不重复', type: 1,},
        {name:'每天', type: 2},
        {name:'每周', type: 3},
        {name:'每月', type: 4},
      ]
    }
  },
  computed: {
    type_name(){
      const {type_list,ptype} = this;
      const aim = type_list.find(item=>{
        return item.type == ptype;
      })
      if(aim !== undefined){
        return aim.name;
      }
      return '不重复';
    }
  },
  methods: {
    changeType(type){
      this.$emit('handleChange', type);
      this.switch_popover = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_time_operating_page{
  width: 90px;
  font-size: 14px;
  color: #333333;
  line-height: 34px;
  background: #FBFBFB;
  border-radius: 4px;
  border: 1px solid #E5E7EA;
  .text{
    padding-right: 5px;
  }
  .icon{
    width: 9px;
    height: 6px;
    background-image: url(~@/assets/images/reminder/select_icon.png)
  }
}
</style>
<style lang="scss">
.components_pop_up_reminder_time_operating_page_popover{
  padding: 5px 0;
  // margin-top: 0px!important;
  min-width: 0!important;
  .list{
    .item{
      @include transition;
      width: 90px;
      background: #FFFFFF;
      font-size: 14px;
      color: #000;
      line-height: 34px;
      padding: 0 10px;
      &:hover{
        background-color: #ddd;
      }
      &.active{
        background-color: #ddd;
        font-weight: bold;
        color:#333;
        // &:hover{
        //   background-color: #ddd;
        // }
      }
    }
  }
}
</style>