<template>
  <div class="components_pop_up_reminder_user_page bbox d-flex align-center">
    <img src="~@/assets/images/reminder/user.png" alt="" class="icon mr-10">
    <div class="main bbox d-flex align-center justify-space-between"
      @click="touchAdd();"
    >
      <div class="no_content cp"
        v-if="no_one"
        @click="touchAdd();"
      >设置提醒对象</div>
      <div class="list_group d-flex align-center flex-wrap"
        v-if="!no_one && !all_status"
      >
        <template
          v-for="(item, index) in selected_list"
        >
          <el-tooltip
            v-if="index < 5"
            :key="index"
            effect="dark"
            :content="$utils.users.getPriorityName({userId: item.userId, name: item.userName})"
            :open-delay="500"
            placement="bottom">
            <div class="avatar_group d-flex align-center">
              <img :src="`${$avatar_url}${item.thumbnail}@!small200`" alt="" class="avatar">
              <div class="delete cp"
                @click.stop="deleteItem(item)"
              ></div>
            </div>
          </el-tooltip>
        </template>
        <div class="more_user cp"
          v-if="users_num > 5"
        >... （共{{users_num}}个联系人）</div>
      </div>
      <div class="all"
        v-if="all_status"
      >全部参与人</div>
      <img
        src="~@/assets/images/reminder/add.png"
        alt=""
        class="button add cp flex-shrink-0"
        @click="touchAdd();"
      >
    </div>
    <select-users
      v-if="switch_select_users"
      title="从任务参与人中选择提醒对象"
      :list="list"
      :full_control="true"
      :total_num="total_num"
      :select_all="true"
      @handleClose="closeSelect"
      @handleSure="sureSelect"
    ></select-users>
  </div>
</template>
<script>
import store from '@/store'
import SelectUsers from '@/components/PopUp/SelectUsers';
export default {
  props: {
    toUsers: {
      type: String,
      default: '',
    },
  },
  components: {
    SelectUsers,
  },
  data(){
    return {
      switch_select_users: false,
      users: [],
      // selected_list: [],
      // list: [],
    }
  },
  computed: {
    all_status(){
      const {list, selected_list, no_one} = this;
      if(no_one){
        return false;
      }

      if(list.length == selected_list.length){
        return true;
      }
      return false;
    },
    no_one(){
      const {selected_list} = this;
      if(selected_list.length == 0 || !selected_list){
        return true;
      }
      return false;
    },
    selected_list(){
      const {toUsers, users} = this;
      let selected_list = [];
      if(toUsers){
        const arr = toUsers.split(',');
        users.forEach(item=>{
          const is_in = arr.find(unit=>{
            return unit == item.userId;
          })
          if(is_in){
            selected_list.push(item);
          }
        })
        // this.$set(this, 'selected_list', selected_list);
      }
      return selected_list;
    },
    users_num(){
      const {toUsers} = this
      if(toUsers){
        return toUsers.split(',').length
      }
      return 0
    },
    task_config_users(){
      return store.state.task_config.users
    },
    list(){
      const {task_config_users, toUsers} = this
      let list = task_config_users
      list.forEach(item=>{
        delete item.hide;
        // delete item.selected;
        // delete item.deletable;
        item.deletable = true
      })
      if(toUsers){
        const arr = toUsers.split(',');
        list.forEach(item=>{
          const is_in = arr.find(unit=>{
            return unit == item.userId;
          })
          if(is_in){
            this.$set(item, 'selected', true);
          }else{
            this.$set(item, 'selected', false);
          }
        })
      }
      return list;
    },
    total_num(){
      return undefined
      const {task} = store.state
      const {tousers} = task.task
      if(tousers){
        return tousers.split(',').length
      }
      return undefined
    }
  },
  methods: {
    async init(){

      const res = await this.getTaskUsers();
      this.$set(this, 'users', this.$tools.deepClone(res));
    },
    async getTaskUsers(){
      const {task_config} = this.$store.state;
      const {users} = task_config;
      let list = this.$tools.deepClone(users);
      list.forEach(item=>{
        delete item.hide;
        delete item.selected;
        delete item.deletable;
      })
      return list;
    },
    touchAdd(){
      const {users, selected_list, toUsers, list} = this;
      // let list = this.$tools.deepClone(users);
    
      if(toUsers){
        const arr = toUsers.split(',');
        list.forEach(item=>{
          const is_in = arr.find(unit=>{
            return unit == item.userId;
          })
          if(is_in){
            this.$set(item, 'selected', true);
          }else{
            this.$set(item, 'selected', false);
          }
        })
      }
      // this.$set(this, 'list', list);


      this.switch_select_users = true;
    },
    closeSelect(){
      this.switch_select_users = false;
    },
    sureSelect(selected_list){
      // this.$set(this, 'selected_list', selected_list);
      this.closeSelect();
      this.handleChangeToUsers(selected_list);
    },
    deleteItem(item){
      const {selected_list} = this;
      const copy_list = this.$tools.deepClone(selected_list);
      const index = copy_list.find(unit=>{
        return unit.userId == item.userId;
      })
      if(index != -1){
        copy_list.splice(index, 1);
      }
      this.$nextTick(function(){
        this.handleChangeToUsers(copy_list);
      })
    },
    handleChangeToUsers(selected_list){
      // const {selected_list} = this;
      let toUsers = '';
      if(selected_list && selected_list.length){
        selected_list.forEach(item=>{
          toUsers += toUsers ? `,${item.userId}` : `${item.userId}`;
        })
      }
      this.$emit('handleChangeToUsers', toUsers);
    }
  },
  mounted(){
    this.init();
  }
  

}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_user_page{
  font-size: 14px;
  color: #333;
  width: 100%;
  .no_content{
    color: #bbb;
  }
  .main{
    flex: 1;
    max-width: 100%;
    min-width: 484px;
    padding: 0 10px;
    height: 36px;
    background: #FBFBFB;
    border-radius: 4px;
    border: 1px solid #E5E7EA;
  }
  .list{
    height: 100%;
  }
  .more_user{
    font-size: 14px;
    color: #999;
  }
  .avatar_group{
    position: relative;
    margin-right: 6px;
    .avatar{
      width: 30px;
      height: 30px;
      border-radius: 4px;
    }
    &:hover{
      .delete{
        opacity: 1;
      }
    }
    .delete{
      @include transition;
      opacity: 0;
      position: absolute;
      top:-4px;
      right: -4px;
      width: 16px;
      height: 16px;
      background: {
        image:url(~@/assets/images/reminder/delete.png);
        color: #fff;
      }
      border-radius: 50%;
      
    }
  }
}
.mr-10{
  margin-right: 10px;
}
</style>