var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_reminder_main_page bbox d-flex flex-column align-start",
    },
    [
      _c("time-group", {
        staticClass: "mb-24",
        attrs: {
          ptype: _vm.ptype,
          remindTime: _vm.remindTime,
          date: _vm.date,
          time: _vm.time,
          rangee: _vm.rangee,
          start_date: _vm.start_date,
          start_time: _vm.start_time,
          end_date: _vm.end_date,
          end_time: _vm.end_time,
        },
        on: {
          handleChange: _vm.changeType,
          handleChangeDate: _vm.handleChangeDate,
          handleChangeTime: _vm.handleChangeTime,
          handleChangeRangee: _vm.handleChangeRangee,
          handleChangeBucket: _vm.handleChangeBucket,
          handleSwtichBucket: _vm.handleSwtichBucket,
        },
      }),
      _c("user-group", {
        staticClass: "mb-24",
        attrs: { toUsers: _vm.toUsers },
        on: { handleChangeToUsers: _vm.handleChangeToUsers },
      }),
      _c("description-group", {
        ref: "description",
        attrs: { content: _vm.content },
        on: { handleChangeContent: _vm.handleChangeContent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }