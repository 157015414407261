var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_pop_up_reminder_operating_page bbox" },
    [
      _c(
        "div",
        { staticClass: "basic bbox d-flex align-center" },
        [
          _c("img", {
            staticClass: "icon mr-10",
            attrs: {
              src: require("@/assets/images/reminder/time.png"),
              alt: "",
            },
          }),
          _c("repetition-widget", {
            staticClass: "mr-10",
            attrs: { ptype: _vm.ptype },
            on: { handleChange: _vm.changeType },
          }),
          _c("date-widget", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.date_status,
                expression: "date_status",
              },
            ],
            ref: "date",
            staticClass: "mr-10",
            attrs: { ptype: _vm.ptype, date: _vm.date, rangee: _vm.rangee },
            on: {
              handleChangeDate: _vm.handleChangeDate,
              handleChangeRangee: _vm.handleChangeRangee,
            },
          }),
          _c("time-widget", {
            attrs: { ptype: _vm.ptype, time: _vm.time },
            on: { handleChangeTime: _vm.handleChangeTime },
          }),
        ],
        1
      ),
      _vm.switch_bucket
        ? _c("time-bucket", {
            attrs: {
              start_date: _vm.start_date,
              start_time: _vm.start_time,
              end_date: _vm.end_date,
              end_time: _vm.end_time,
            },
            on: {
              handleChangeBucket: _vm.handleChangeBucket,
              handleClose: _vm.closeBucket,
            },
          })
        : _vm._e(),
      _vm.fun_status
        ? _c("div", { staticClass: "basic bbox d-flex align-center" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.switch_bucket,
                    expression: "!switch_bucket",
                  },
                ],
                staticClass: "add_range_button d-flex align-center cp",
                on: {
                  click: function ($event) {
                    return _vm.openBucket()
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "add",
                  attrs: {
                    src: require("@/assets/images/reminder/add_grey.png"),
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "text" }, [
                  _vm._v("设置提醒生效期间"),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }