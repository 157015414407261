<template>
  <div class="components_pop_up_reminder_description_page bbox d-flex align-start">
    <img src="~@/assets/images/reminder/description.png" alt="" class="icon mr-10">
    <el-input
      class="textarea"
      type="textarea"
      placeholder="填写提醒描述"
      v-model="description"
      maxlength="80"
      :autosize="{minRows: 1, maxRows: 3}"
      show-word-limit
      resize="none"
      @change.native="changDescription();"
    >
    </el-input>

  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    }
  },
  components: {
  },
  data(){
    return {
      description: '',
    }
  },
  methods:{
    init(){
      const {content} = this;
      this.description = content;
    },
    changDescription(){
      const {description} = this;
      this.$emit('handleChangeContent', description);
    }
  },
  mounted(){
    this.init();
  }

}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_description_page{
  font-size: 14px;
  color: #333;
  width: 100%;
  .icon{
    height: 20px;
    margin: {
      top: 10px;
      bottom: 10px;
    }
  }
  .no_content{
    color: #bbb;
  }
  .main{
    max-width: 100%;
    min-width: 484px;
    padding: 0 10px;
    height: 36px;
    background: #FBFBFB;
    border-radius: 4px;
    border: 1px solid #E5E7EA;
  }
  .textarea{
    flex: 1;
    max-width: 100%;
    min-width: 484px;
  }
}
.mr-10{
  margin-right: 10px;
}
</style>
<style lang="scss">
.components_pop_up_reminder_description_page .el-textarea__inner{
  @include placeholder(#bbb);
  padding: 8px 10px;
  line-height: 20px;
  background-color: #FBFBFB;
  font-family: 'MicroSoft YaHei', 'PingFang SC';
  outline: none;
  border: 1px solid #E5E7EA;
  &:focus{
    border: 1px solid #E5E7EA;
  }

}
</style>