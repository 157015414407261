<template>
  <el-popover
    placement="bottom-start"
    popper-class="components_pop_up_reminder_time_date_page_popover"
    trigger="click"
    v-model="switch_popover"
    :visible-arrow="false"
    :disabled="poppver_disable_status"
  >
    <!-- 日期选择 -->
    <div class="multiple_date_list">
      <div class="date_list bbox d-flex align-center justify-start flex-wrap">
        <template
          v-for="(item, index) in date_list"
        >
          <div
            :key="index"
            class="date_unit flex-shrink-0 d-flex align-center justify-center bbox un_sel cp"
            :class="{active: item.active}"
            @click="touchDate(item)"
          >{{item.name}}</div>
        </template>
      </div>
      <div class="hint">*如当月无被选日期，将不会提醒</div>
      <div class="operating_group d-flex justify-end bbox">
        <div class="button btn_dark"
          @click="doSelectDate();"
        >完成</div>
        <div class="button btn_light"
          @click="closeSelectDate();"
        >取消</div>
      </div>
    </div>
    <template
      slot="reference"
    >
      <div class="components_pop_up_reminder_time_date_page bbox d-flex align-center cp flex-shrink-0 justify-center un_sel"
      >
        <div class="week_group d-flex align-center"
          v-if="week_status"
        >
          <template
            v-for="(item,index) in week_list"
          >
            <div
              :key="index"
              class="week"
              :class="{active: item.active}"
              @click="touchWeek(item)"
            >{{item.name}}</div>
          </template>
        </div>
        <div class="text bbox"
          v-else
          :class="{no_content}"
        >{{content}}</div>

        <!-- <div class="icon"></div> -->
          <el-date-picker
            class="picker"
            type="date"
            :clearable="false"
            ref="picker"
            placeholder="选择日期"
            :picker-options="pickerOptions"
            v-show="picker_status"
            v-model="time"
            value-format="yyyy-MM-dd"
            @change="changePicker"
          >
        </el-date-picker>
      </div>
    </template>
  </el-popover>
</template>

<script>
export default {
  props: {
    ptype: {
      type: Number,
      default: 1,
    },
    date: {
      type: String,
      default: '',
    },
    rangee: {
      type: String,
      default: '',
    },
    remindTime: {
      type: String,
      default: '',
    },
  },
  computed:{
    picker_status(){
      const {ptype} = this;
      if(ptype == 1) {
        return true;
      }
      return false;
    },
    poppver_disable_status(){
      const {picker_status, ptype} = this;

      if(picker_status){
        return true;
      }
      if(ptype == 3){
        return true;
      }
      return false;
    },
    no_content(){
      const {ptype, date, rangee} = this;
      if(ptype == 1 && date){
        return false;
      }
      if(ptype == 4 && rangee){
        return false;
      }
      return true;
    },
    content(){
      const {ptype, date, rangee} = this;
      if(ptype == 1){
        if(date){
          return date;
        }
      }
      if(ptype == 4){
        if(rangee){
          return rangee + '日';
        }
      }
      return '提醒日期'
    },
    week_status(){
      const {ptype} = this;
      if(ptype == 3){
        return true;
      }
      return false;
    },
  },
  data(){
    return {
      time:'',
      switch_popover: false,
      pickerOptions: {
        disabledDate(time){
          const today = new Date();
          today.setHours(0);
          today.setMinutes(0);
          today.setSeconds(0);
          today.setMilliseconds(0);
          return time.getTime() < today.getTime();
        }
      },
      date_list: [
        {name:1},
        {name:2},
        {name:3},
        {name:4},
        {name:5},
        {name:6},
        {name:7},
        {name:8},
        {name:9},
        {name:10},
        {name:11},
        {name:12},
        {name:13},
        {name:14},
        {name:15},
        {name:16},
        {name:17},
        {name:18},
        {name:19},
        {name:20},
        {name:21},
        {name:22},
        {name:23},
        {name:24},
        {name:25},
        {name:26},
        {name:27},
        {name:28},
        {name:29},
        {name:30},
        {name:31},
      ],
      week_list: [
        {name:'一'},
        {name:'二'},
        {name:'三'},
        {name:'四'},
        {name:'五'},
        {name:'六'},
        {name:'日'},
      ]
      
      
    }
  },
  mounted(){
  },
  methods: {
    touchButton(){
      // this.$refs.picker.timePickerVisible= true;
      // this.$refs.picker.handleFocus();

    },
    changePicker(val){
      // console.log(val);
      this.$emit('handleChangeDate', val);
    },
    touchDate(item){
      this.$set(item, 'active', !item.active);
    },
    doSelectDate(){
      const {date_list} = this;
      let rangee = '';
      date_list.forEach((item)=>{
        if(item.active){
          rangee += rangee ? `,${item.name}`: `${item.name}`
        }
      })
      this.$emit('handleChangeRangee', rangee);
      this.$nextTick(function(){
        this.closeSelectDate();
      })
    },
    closeSelectDate(){
      this.switch_popover = false;
      this.initDateList();
    },
    initDateList(){
      const {rangee, date_list, ptype} = this;
      if(rangee && ptype == 4){
        const arr = rangee.split(',');
        date_list.forEach(item=>{
          const is_in = arr.find((unit)=>{
            return unit == item.name;
          })
          if(is_in !== undefined){
            this.$set(item, 'active', true);
          }else{
            this.$set(item, 'active', false);
          }
        })
      }
    },
    touchWeek(item){
      this.$set(item, 'active', !item.active);
      this.doSelectWeek();
    },
    doSelectWeek(){
      const {week_list} = this;
      let rangee = '';
      week_list.forEach((item)=>{
        if(item.active){
          rangee += rangee ? `,${item.name}`: `${item.name}`
        }
      })
      this.$emit('handleChangeRangee', rangee);
      // this.$nextTick(function(){
      //   this.closeSelectWeek();
      // })
    },
    // closeSelectWeek(){
    //   this.switch_popover = false;
    //   this.initWeekList();
    // },
    initWeekList(){
      const {rangee, week_list, ptype} = this;
      // console.log(rangee, week_list, ptype)
      if(rangee && ptype == 3){
        const arr = rangee.split(',');
        week_list.forEach(item=>{
          const is_in = arr.find((unit)=>{
            return unit == item.name;
          })
          if(is_in !== undefined){
            this.$set(item, 'active', true);
          }else{
            this.$set(item, 'active', false);
          }
        })
      }
    },
    init(){
      this.initDateList();
      this.initWeekList();
    },
    // defaultValue(){
    //   const {remindTime, date, time} = this;
    //   if(date){
    //     this.time= date;
    //     return Number(this.$moment(`${date} 00:00`).format('x'));
    //   }
    //   this.time = this.$moment(`${date} 00:00`).format('YYYY-MM-DD');
    //   return Date.now();
    // },
  },
}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_time_date_page{
  
  
  
  font-size: 14px;
  color: #333333;
  line-height: 34px;
  background: #FBFBFB;
 
  position: relative;
  z-index: 5;
  .week_group{
    border-radius: 4px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #E5E7EA;
    .week{
      @include transition;
      width:40px;
      text-align: center;
      &.active{
        color: #fff;
        background-color: $main_blue;
      }
    }
  }
  .text{
    min-width: 120px;
    max-width: 200px;
    padding: 0 10px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #E5E7EA;
    @include single_line_intercept;
    &.no_content{
      color: #bbb;
    }
  }
  .icon{
    width: 9px;
    height: 6px;
    background-image: url(~@/assets/images/reminder/select_icon.png)
  }
  .picker{
    position: absolute;
    top:0;
    left:0 ;
    z-index: 5;
    opacity: 0;
    width: 100%;
    height: 100%;
    // overflow: hidden;
  }
 
}
</style>
<style lang="scss">
.components_pop_up_reminder_time_date_page_popover{
  padding: 5px 0;
  // margin-top: 0px!important;
  min-width: 0!important;
  .list{
    .item{
      @include transition;
      width: 90px;
      background: #FFFFFF;
      font-size: 14px;
      color: #000;
      line-height: 34px;
      padding: 0 10px;
      &:hover{
        font-weight: bold;
      }
      &.active{
        background-color: #ddd;
        font-weight: bold;
        color:#333;
        // &:hover{
        //   background-color: #ddd;
        // }
      }
    }
  }
  .multiple_date_list{
    padding: 10px;
    .date_list{
      width: 212px;
      background: #FBFBFB;
      border-radius: 4px;
      border: 1px solid #E5E7EA;
      overflow: hidden;
      .date_unit{
        @include transition;
        width: 30px;
        height: 30px;
        font-size: 14px;
        color: #333;
        border: {
          bottom: 1px solid #E5E7EA;
          right: 1px solid #E5E7EA;
        }
        &:nth-of-type(7n){
          border-right: none;
        }
        &:nth-of-type(n+29){
          border-bottom: none;
        }
        &.active{
          background-color: $main_blue;
          color: #fff;
        }
      }
    }
    .hint{
      font-size: 12px;
      color: #999999;
      padding: {
        top: 6px;
        bottom: 16px;
      }
    }
    .operating_group{
      .button{
        font-size: 12px;
        line-height: 26px;
        border-radius: 4px;
        margin-left: 10px;
        padding: 0 12px;
      }
    }
  }
}
.components_pop_up_reminder_time_date_page{
  .el-date-editor{
    .el-input__inner{
      width: 100%;
      height: 100%;
      padding: {
        left: 0;
        right: 0;
        border: none;
      }
    }
    .el-input__prefix{
      display: none;
    }
  }
}
</style>