<template>
  <div class="components_pop_up_reminder_operating_page bbox">
    <div class="basic bbox d-flex align-center">
      <img src="~@/assets/images/reminder/time.png" alt="" class="icon mr-10">
      <repetition-widget
        class="mr-10"
        :ptype="ptype"
        @handleChange="changeType"
      ></repetition-widget>
      <date-widget
        v-show="date_status"
        class="mr-10"
        :ptype="ptype"
        ref="date"
        @handleChangeDate="handleChangeDate"
        :date="date"
        :rangee="rangee"
        @handleChangeRangee="handleChangeRangee"
      ></date-widget>
      <time-widget
        :ptype="ptype"
        :time="time"
        @handleChangeTime="handleChangeTime"
      ></time-widget>
    </div>
    <time-bucket
      v-if="switch_bucket"
      @handleChangeBucket="handleChangeBucket"
      @handleClose="closeBucket"
      :start_date="start_date"
      :start_time="start_time"
      :end_date="end_date"
      :end_time="end_time"
    ></time-bucket>
    <div class="basic bbox d-flex align-center"
      v-if="fun_status"
    >
      <div class="add_range_button d-flex align-center cp"
        v-show="!switch_bucket"
        @click="openBucket();"
      >
        <img src="~@/assets/images/reminder/add_grey.png" alt="" class="add">
        <div class="text">设置提醒生效期间</div>
      </div>
    </div>

  </div>
</template>
<script>
import DateWidget from './Time/Date.vue';
import DayWidget from './Time/Day.vue';
import TimeWidget from './Time/Time.vue';
import RepetitionWidget from './Time/Repetition.vue';
import TimeBucket from './Time/Bucket.vue';
export default {
  props: {
    ptype: {
      type: Number,
      default: 1,
    },
    year: {
      type: String,
      default: '',
    },
    month: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '',
    },
    start_date: {
      type: String,
      default: '',
    },
    start_time: {
      type: String,
      default: '',
    },
    end_date: {
      type: String,
      default: '',
    },
    end_time: {
      type: String,
      default: '',
    },
    rangee: {
      type: String,
      default: '',
    },
    remindTime: {
      type: String,
      default: '',
    },
  },
  computed: {
    date_status(){
      const {ptype} = this;
      if(ptype == 2){
        return false;
      }
      return true;
    },
    fun_status(){
      const {ptype} = this;
      if(ptype == 1){
        return false;
      }
      return true;
    }
  },
  data(){
    return {
      switch_bucket: false,
    }
  },
  components: {
    DateWidget,
    DayWidget,
    TimeWidget,
    RepetitionWidget,
    TimeBucket,
  },
  methods:{
    changeType(type){
      this.$emit('handleChange', type);
      if(type == 1){
        // 不重复时没有时间范围
        this.closeBucket();
      }
      this.$nextTick(function(){
        this.$refs.date.init();
      })
    },
    handleChangeDate(data){
      this.$emit('handleChangeDate', data);
    },
    handleChangeTime(time){
      this.$emit('handleChangeTime', time);
    },
    handleChangeRangee(rangee){
      this.$emit('handleChangeRangee', rangee);
    },
    openBucket(){
      this.switch_bucket = true;
      this.$emit('handleSwtichBucket', true);
    },
    closeBucket(){
      this.switch_bucket = false;
      this.$emit('handleSwtichBucket', false);
    },
    handleChangeBucket(bucket){
      this.$emit('handleChangeBucket', bucket);
    }
  },
  mounted(){
    this.$nextTick(function(){
      this.$refs.date.init();
    })
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_operating_page{
  .basic{
    .icon{

    }
  }
  .add_range_button{
    padding-top: 16px;
    margin-left: 30px;
    .text{
      font-size: 14px;
      color: #888;
      padding-left: 10px;
    }
  }
}
.mr-10{
  margin-right: 10px;
}
</style>