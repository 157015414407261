<template>
  <div
    class="components_pop_up_reminder_main_page  bbox d-flex flex-column align-start"
  >
    <time-group
      class="mb-24"
      :ptype="ptype"
      @handleChange="changeType"
      @handleChangeDate="handleChangeDate"
      @handleChangeTime="handleChangeTime"
      @handleChangeRangee="handleChangeRangee"
      :remindTime="remindTime"
      :date="date"
      :time="time"
      :rangee="rangee"
      :start_date="start_date"
      :start_time="start_time"
      :end_date="end_date"
      :end_time="end_time"
      @handleChangeBucket="handleChangeBucket"
      @handleSwtichBucket="handleSwtichBucket"
    ></time-group>
    <user-group
      class="mb-24"
      :toUsers="toUsers"
      @handleChangeToUsers="handleChangeToUsers"
    ></user-group>
    <description-group
      :content="content"
      @handleChangeContent="handleChangeContent"
      ref="description"
    ></description-group>
  </div>
</template>

<script>
import TimeGroup from './Time.vue';
import UserGroup from './User.vue';
import DescriptionGroup from './Description.vue';
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    conId: {
      type: Number,
      default: null,
    },
    taskId: {
      type: Number,
      default: null,
    },
  },
  data(){
    return {
      content: '',
      endTime: '',
      ptype: 1,
      rangee: '',
      remindTime: '',
      startTime: '',
      toUsers: '',
      date: '',
      time: '',
      start_date: '',
      start_time: '',
      end_date: '',
      end_time: '',
      switch_bucket: false,
    }
  },
  components: {
    TimeGroup,
    UserGroup,
    DescriptionGroup,
  },
  methods: {
    changeType(type){
      this.ptype = type;
    },
    handleChangeDate(date){
      this.date = date;
    },
    handleChangeTime(time){
      this.time = time;
    },
    handleChangeRangee(rangee){
      this.rangee = rangee;
    },
    handleChangeToUsers(toUsers){
      this.toUsers = toUsers;
    },
    handleChangeContent(content){
      this.content = content;
      // console.log(content);
    },
    handleChangeBucket(bucket){
      this.start_date = bucket[0].split(' ')[0];
      this.start_time = bucket[0].split(' ')[1];
      this.end_date = bucket[1].split(' ')[0];
      this.end_time = bucket[1].split(' ')[1];
    },
    handleSwtichBucket(type){
      this.switch_bucket = type;
    },
    init(){
      const {data} = this;
      const {conId, content, endTime, ptype, rangee, remindTime, startTime, taskId, toUsers, toUsersName, toUsersPhoto, userId, userName, userPhoto, } = data || {};
      this.content = content;
      this.endTime = endTime;
      this.ptype = ptype || 1;
      this.rangee = rangee;
      this.remindTime = String(remindTime);
      this.startTime = startTime;
      this.toUsers = toUsers;
      if(remindTime){
        this.date = this.$moment(new Date(remindTime)).format('YYYY-MM-DD');
        this.time = this.$moment(new Date(remindTime)).format('HH:mm');
      }
      if(startTime){
        this.start_date = this.$moment(new Date(startTime)).format('YYYY-MM-DD');
        this.start_time = this.$moment(new Date(startTime)).format('HH:mm');
      }
      if(endTime){
        this.end_date = this.$moment(new Date(endTime)).format('YYYY-MM-DD');
        this.end_time = this.$moment(new Date(endTime)).format('HH:mm');
      }
      // console.log(this.remindTime)
      this.$nextTick(function(){
        this.$refs.description.init();
      })
    },
    async doSure(){
      const {conId,taskId, content,endTime,ptype,rangee,remindTime,startTime,toUsers,date,time,start_date,start_time,end_date,end_time,switch_bucket} = this;
      const {id, custom_index} = this.data || {};
      // console.log({conId,taskId, content,endTime,ptype,rangee,remindTime,startTime,toUsers,date,time,start_date,start_time,end_date,end_time, switch_bucket});
      const data = {
        content: content || '',
        pType:ptype,
        toUsers,
        startTime: '',
        rangee: '',
      }
      // console.log(!date);
      if(!time){
        this.$notice({desc: '请选择时间'});
        return;
      }
      if(!toUsers){
        this.$notice({desc: '请选择提醒对象'});
        return;
      }
      if(ptype == 1){
        if(!date){
          this.$notice({desc: '请选择日期'});
          return;
        }
       
        data.remindTime = `${date} ${time}`;
      }else{
        data.remindTime = `${this.$moment().format('YYYY-MM-DD')} ${time}`;
      }
      if(switch_bucket){
        if(!start_date){
          this.$notice({desc: '请选择开始日期'});
          return;
        }
        if(!start_time){
          this.$notice({desc: '请选择开始时间'});
          return;
        }
        if(!end_date){
          this.$notice({desc: '请选择结束日期'});
          return;
        }
        if(!end_time){
          this.$notice({desc: '请选择结束时间'});
          return;
        }
        data.startTime=`${start_date} ${start_time}`;
        data.endTime=`${end_date} ${end_time}`;
      }
      if(ptype == 3 || ptype == 4){
        if(!rangee){
          this.$notice({desc: '请选择范围'});
          return;
        }
        data.rangee = rangee;
      }
      // console.log(conId, taskId)
      let reminder ;
      if(conId && taskId){
        // 提交
        if(id){
          data.remindId = id;
          
          const res = await this.$api.moduleTask.editReminder(data);
          // this.$emit('handleEdit', res);
          if(res.message == 'success'){
            reminder = res.contents.remind;
          }
        }else{
          data.taskId = taskId;
          data.conId = conId;
          const res = await this.$api.moduleTask.createReminder(data);
          // this.$emit('handleCreate', res);
          if(res.message == 'success'){
            reminder = res.contents.remind;
          }
        }

        
      }else{
        reminder = {
          content: data.content,
          createTime: Date.now(),
          endTime: data.endTime ? Number(this.$moment(data.endTime).format('x')) : null,
          ptype: data.pType,
          rangee: data.rangee,
          remindTime: Number(this.$moment(data.remindTime).format('x')),
          startTime: data.startTime ? Number(this.$moment(data.startTime).format('x')) : null,
          toUsers: data.toUsers,
          toUsersName: null,
          toUsersPhoto: null,
          upload_data: data, // 上传用数据
          custom_index:custom_index,
        }
        
      }
      // console.log(reminder);
      if(reminder){
        let now = new Date();
        now.setSeconds(0);
        now.setMilliseconds(0);
        now = this.$moment(now).format('x');
        if(reminder.ptype === 1 && (this.$moment(reminder.remindTime).format('x') < now)){
          this.$notice({desc: '提醒时间不能小于当前时间'});
          return;
        }
        // if(reminder.ptype === 1){
        // }
        this.$emit('handleComplete', reminder);
        this.$emit('handleClose');
        this.$notice({desc: '提交成功'});
      }
    }
  },
  mounted(){
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_main_page{
  padding: 40px;
  .mb-24{
    margin-bottom: 24px;
  }
}
</style>