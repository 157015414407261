var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_reminder_user_page bbox d-flex align-center",
    },
    [
      _c("img", {
        staticClass: "icon mr-10",
        attrs: { src: require("@/assets/images/reminder/user.png"), alt: "" },
      }),
      _c(
        "div",
        {
          staticClass: "main bbox d-flex align-center justify-space-between",
          on: {
            click: function ($event) {
              return _vm.touchAdd()
            },
          },
        },
        [
          _vm.no_one
            ? _c(
                "div",
                {
                  staticClass: "no_content cp",
                  on: {
                    click: function ($event) {
                      return _vm.touchAdd()
                    },
                  },
                },
                [_vm._v("设置提醒对象")]
              )
            : _vm._e(),
          !_vm.no_one && !_vm.all_status
            ? _c(
                "div",
                { staticClass: "list_group d-flex align-center flex-wrap" },
                [
                  _vm._l(_vm.selected_list, function (item, index) {
                    return [
                      index < 5
                        ? _c(
                            "el-tooltip",
                            {
                              key: index,
                              attrs: {
                                effect: "dark",
                                content: _vm.$utils.users.getPriorityName({
                                  userId: item.userId,
                                  name: item.userName,
                                }),
                                "open-delay": 500,
                                placement: "bottom",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "avatar_group d-flex align-center",
                                },
                                [
                                  _c("img", {
                                    staticClass: "avatar",
                                    attrs: {
                                      src: `${_vm.$avatar_url}${item.thumbnail}@!small200`,
                                      alt: "",
                                    },
                                  }),
                                  _c("div", {
                                    staticClass: "delete cp",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteItem(item)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                  _vm.users_num > 5
                    ? _c("div", { staticClass: "more_user cp" }, [
                        _vm._v(
                          "... （共" + _vm._s(_vm.users_num) + "个联系人）"
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.all_status
            ? _c("div", { staticClass: "all" }, [_vm._v("全部参与人")])
            : _vm._e(),
          _c("img", {
            staticClass: "button add cp flex-shrink-0",
            attrs: {
              src: require("@/assets/images/reminder/add.png"),
              alt: "",
            },
            on: {
              click: function ($event) {
                return _vm.touchAdd()
              },
            },
          }),
        ]
      ),
      _vm.switch_select_users
        ? _c("select-users", {
            attrs: {
              title: "从任务参与人中选择提醒对象",
              list: _vm.list,
              full_control: true,
              total_num: _vm.total_num,
              select_all: true,
            },
            on: { handleClose: _vm.closeSelect, handleSure: _vm.sureSelect },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }