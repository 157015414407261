var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-start",
        "popper-class":
          "components_pop_up_reminder_time_operating_page_popover",
        trigger: "click",
        "visible-arrow": false,
      },
      model: {
        value: _vm.switch_popover,
        callback: function ($$v) {
          _vm.switch_popover = $$v
        },
        expression: "switch_popover",
      },
    },
    [
      _c(
        "div",
        { staticClass: "list" },
        [
          _vm._l(_vm.type_list, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass: "item bbox cp",
                  class: { active: item.type == _vm.ptype },
                  on: {
                    click: function ($event) {
                      return _vm.changeType(item.type)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              ),
            ]
          }),
        ],
        2
      ),
      _c("template", { slot: "reference" }, [
        _c(
          "div",
          {
            staticClass:
              "components_pop_up_reminder_time_operating_page bbox d-flex align-center cp flex-shrink-0 justify-center un_sel",
          },
          [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.type_name))]),
            _c("div", { staticClass: "icon" }),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }