var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom-start",
        "popper-class": "components_pop_up_reminder_time_time_page_popover",
        trigger: "click",
        "visible-arrow": false,
        disabled: _vm.poppver_disable_status,
      },
      model: {
        value: _vm.switch_popover,
        callback: function ($$v) {
          _vm.switch_popover = $$v
        },
        expression: "switch_popover",
      },
    },
    [
      _c("template", { slot: "reference" }, [
        _c(
          "div",
          {
            staticClass:
              "components_pop_up_reminder_time_time_page bbox d-flex align-center cp flex-shrink-0 justify-center un_sel",
          },
          [
            _c(
              "div",
              { staticClass: "text", class: { no_content: _vm.no_content } },
              [_vm._v(_vm._s(_vm.content))]
            ),
            _c("el-time-picker", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.picker_status,
                  expression: "picker_status",
                },
              ],
              ref: "picker",
              staticClass: "picker",
              attrs: {
                clearable: false,
                placeholder: "选择日期",
                "picker-options": _vm.pickerOptions,
                "default-value": _vm.defaultValue(),
                format: "HH:mm",
                "value-format": "HH:mm",
              },
              on: { change: _vm.changePicker },
              model: {
                value: _vm.picker_time,
                callback: function ($$v) {
                  _vm.picker_time = $$v
                },
                expression: "picker_time",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }