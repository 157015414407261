var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "components_pop_up_reminder_time_range_page bbox d-flex align-center_group",
    },
    [
      _c(
        "div",
        { staticClass: "main bbox d-flex align-center cp" },
        [
          _c(
            "div",
            {
              staticClass: "date btn mr-10",
              class: { no_content: _vm.start_date == "" },
            },
            [_vm._v(_vm._s(_vm.start_date_content))]
          ),
          _c(
            "div",
            {
              staticClass: "time btn mr-10",
              class: { no_content: _vm.start_time == "" },
            },
            [_vm._v(_vm._s(_vm.start_time_content))]
          ),
          _c("div", { staticClass: "text mr-10" }, [_vm._v("至")]),
          _c(
            "div",
            {
              staticClass: "date btn mr-10",
              class: { no_content: _vm.end_date == "" },
            },
            [_vm._v(_vm._s(_vm.end_date_content))]
          ),
          _c(
            "div",
            {
              staticClass: "time btn",
              class: { no_content: _vm.end_time == "" },
            },
            [_vm._v(_vm._s(_vm.end_time_content))]
          ),
          _c("el-date-picker", {
            staticClass: "picker cp",
            attrs: {
              clearable: false,
              type: "datetimerange",
              "value-format": "yyyy-MM-dd HH:mm",
              "picker-options": _vm.pickerOptions,
              "default-value": new Date().getTime(),
            },
            on: { change: _vm.changeBucket },
            model: {
              value: _vm.ranger_picker,
              callback: function ($$v) {
                _vm.ranger_picker = $$v
              },
              expression: "ranger_picker",
            },
          }),
        ],
        1
      ),
      _c("img", {
        staticClass: "delete",
        attrs: { src: require("@/assets/images/reminder/delete.png"), alt: "" },
        on: {
          click: function ($event) {
            return _vm.doClose()
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }