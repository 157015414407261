<template>
  <div
    class="components_pop_up_reminder_time_range_page bbox d-flex align-center_group"
  > 
    <div class="main bbox d-flex align-center cp">
      <div class="date btn mr-10"
        :class="{no_content: start_date == ''}"
      >{{start_date_content}}</div>
      <div class="time btn mr-10"
        :class="{no_content: start_time == ''}"
      >{{start_time_content}}</div>
      <div class="text mr-10">至</div>
      <div class="date btn mr-10"
        :class="{no_content: end_date == ''}"
      >{{end_date_content}}</div>
      <div class="time btn"
        :class="{no_content: end_time == ''}"
      >{{end_time_content}}</div>
      <el-date-picker
        class="picker cp"
        :clearable="false"
        v-model="ranger_picker"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm"
        :picker-options="pickerOptions"
        @change="changeBucket"
        :default-value="new Date().getTime()"
      >
      </el-date-picker>
    </div>
    <img src="~@/assets/images/reminder/delete.png" alt="" class="delete"
      @click="doClose();"
    >
  </div>
</template>

<script>
export default {
  props: {
    start_date: {
      type: String,
      default: '',
    },
    start_time: {
      type: String,
      default: '',
    },
    end_date: {
      type: String,
      default: '',
    },
    end_time: {
      type: String,
      default: '',
    },
  },
  computed:{
 
    start_date_content(){
      const {start_date} = this;
      if(start_date !== ''){
        return start_date
      }
      
      return '开始日期'
    },
    start_time_content(){
      const {start_time} = this;
      if(start_time !== ''){
        return start_time
      }
      
      return '时间'
    },
    end_date_content(){
      const {end_date} = this;
      if(end_date !== ''){
        return end_date
      }
      
      return '结束日期'
    },
    end_time_content(){
      const {end_time} = this;
      if(end_time !== ''){
        return end_time
      }
      
      return '时间'
    },
  },
  data(){
    return {
      ranger_picker: '',
      pickerOptions: {
        format: 'HH:mm',
        disabledDate(time){
          const today = new Date();
          today.setHours(0);
          today.setMinutes(0);
          today.setSeconds(0);
          today.setMilliseconds(0);
          return time.getTime() < today.getTime();
        },
      }
    }
  },
  methods: {
    changeBucket(val){
      this.$emit('handleChangeBucket', val);
    },
    doClose(){
      this.$emit('handleClose');
    },
    init(){
      const {start_date, start_time, end_date, end_time} = this;
      if(start_date !== '' && start_time !== '' && end_date !== '' && end_time !== ''){
        this.$set(this, 'ranger_picker', [
          `${start_date} ${start_time}`,
          `${end_date} ${end_time}`,
        ])
      }
    },
  },
  mounted(){
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_time_range_page{
  padding: {
    top: 10px;
  }
  padding-left: 30px;
  width: 100%;
  .main{
    position: relative;
  }
  .picker{
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    position: absolute;
    padding:0;
    opacity: 0;
  }
  .btn{
    @include bbox;
    text-align: center;
    width: 120px;
    font-size: 14px;
    color: #333333;
    line-height: 34px;
    background: #FBFBFB;
    border-radius: 4px;
    border: 1px solid #E5E7EA;
    position: relative;
    &.no_content{
      color: #bbb;
    }
    &.time{
      width: 60px;
    }
  }
  .delete{
    margin-left: 10px;
  }
  .mr-10{
    margin-right: 10px;
  }
}
</style>